import styled from 'styled-components';
import { breakpointsMedia } from '@app/styles/breakpoints';

export const LinkCapturePageWrapper = styled.div`
  height: 100vh;
  height: 100dvh;
  display: grid;
  grid-template-rows: max-content 1fr max-content;
`;

export const LinkCapturePageHero = styled.div`
  background: ${({ theme }) => theme.colors.primary};
  padding-bottom: 20px;
`;

export const LinkCapturePageTitle = styled.div<{ $smallPadding: boolean }>`
  max-width: 1200px;
  margin: 0 auto;
  padding: ${({ $smallPadding }) => ($smallPadding ? '20px 20px' : '50px 20px')};
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSizes.base};
  text-align: center;

  ${breakpointsMedia.md} {
    padding: 40px 20px;
    font-size: ${({ theme }) => theme.fontSizes.xxl};
  }
`;

export const LinkCapturePageContent = styled.div`
  overflow: hidden;
  transform: translateY(-20px);
  padding: 0 10px;
  display: flex;
  justify-content: center;
`;
