import { useSuspenseQuery } from '@apollo/client';
import { graphql } from '@app/__generated__';
import { CaptureIdProvider } from '@app/feautres/capture/providers/capture-id-provider';
import { GalleryIdProvider } from '@app/feautres/gallery/providers/gallery-id-provider';
import { useEffect, useState } from 'react';
import {
  LinkCapturePageContent,
  LinkCapturePageHero,
  LinkCapturePageTitle,
  LinkCapturePageWrapper,
} from './link-capture-page.styles';
import { CapturePreview } from '@app/feautres/capture/components/capture-preview';
import { CaptureActions } from '@app/feautres/capture/components/capture-actions';
import { Ga4StatsProvider } from '@app/feautres/stats/providers/ga4-stats.provider';
import { useElementSize } from '@app/hooks/use-element-size';
import { Size } from 'recharts/types/util/types';

const captureWithGalleryDocument = graphql(`
  query captureWithGallery($captureId: UUID!) {
    capture(captureId: $captureId) {
      id
      shortId
      uploadFilename
      status
      file {
        path
      }
      preview {
        path
      }
      createdAt
      gallery {
        id
        name
        slug
      }
    }
  }
`);

export const LinkCapturePage = () => {
  const captureShortId = document.location.pathname.match(/(\w*)$/)?.[0] || '';
  const { data } = useSuspenseQuery(captureWithGalleryDocument, { variables: { captureId: captureShortId } });
  const gallery = data.capture.gallery!;
  const [ref, size] = useElementSize();
  const [isVertical, setIsVertical] = useState(false);

  useEffect(() => {
    if (gallery?.name) return;
    document.title = gallery.name;
  }, [gallery]);

  const handleLoad = (targetSize: Size) => {
    if (targetSize.height > targetSize.width) setIsVertical(true);
  };

  return (
    <GalleryIdProvider galleryId={gallery.id}>
      <CaptureIdProvider captureId={data.capture.id}>
        <Ga4StatsProvider>
          <LinkCapturePageWrapper>
            <LinkCapturePageHero>
              <LinkCapturePageTitle $smallPadding={isVertical}>{gallery.name}</LinkCapturePageTitle>
            </LinkCapturePageHero>
            <LinkCapturePageContent ref={ref}>
              {size && (
                <CapturePreview capture={data.capture} maxWidth={size.width} maxHeight={size.height} onLoad={handleLoad} />
              )}
            </LinkCapturePageContent>
            <div style={{ background: 'red' }}>
              <CaptureActions capture={data.capture} />
            </div>
          </LinkCapturePageWrapper>
        </Ga4StatsProvider>
      </CaptureIdProvider>
    </GalleryIdProvider>
  );
};
