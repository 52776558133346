import { FC, ReactNode, useCallback, useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Ga4StatsContext } from '../contexts/ga4-stats.context';
import { useGalleryId } from '@app/feautres/gallery/contexts/gallery-id.context';
import { useCaptureId } from '@app/feautres/capture/contexts/capture-id.context';
import { Ga4Action } from '../types/ga4-action.type';

interface Ga4StatsProviderProps {
  children: ReactNode;
}

let isInitialized = false;

export const Ga4StatsProvider: FC<Ga4StatsProviderProps> = ({ children }) => {
  const captureId = useCaptureId();
  const galleryId = useGalleryId();

  const sendEvent = useCallback(
    (eventName: string) => {
      ReactGA.ga('send', {
        hitType: 'event',
        eventAction: eventName,
        gallery_id: galleryId,
        gallery_item_id: captureId,
      });
    },
    [captureId, galleryId],
  );

  useEffect(() => {
    if (isInitialized) return;
    isInitialized = true;
    ReactGA.initialize(import.meta.env.VITE_GA_MEASUREMENT_ID);
    sendEvent('item_view');
  }, [sendEvent]);

  const register = useCallback(
    (action: Ga4Action) => {
      switch (action) {
        case 'shareLink':
          return sendEvent('item_share_link');
        case 'shareFile':
          return sendEvent('item_share_file');
        case 'download':
          return sendEvent('item_download');
      }
    },
    [sendEvent],
  );

  return <Ga4StatsContext.Provider value={{ register }}>{children}</Ga4StatsContext.Provider>;
};
