import { ApolloProvider } from '@apollo/client';
import { publicClient } from '@app/apollo/public-client';
import { theme } from '@app/styles/theme';
import { ThemeProvider } from 'styled-components';
import { GlobalStyles } from '@app/styles/global';
import { LinkCapturePage } from './pages/link-capture';
import { Boundary } from '@app/components/boundary';
import { ToastsContainer } from '@app/components/toasts-container';

export const App = () => {
  return (
    <ApolloProvider client={publicClient}>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <ToastsContainer />
        <Boundary>
          <LinkCapturePage />
        </Boundary>
      </ThemeProvider>
    </ApolloProvider>
  );
};
